// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { media, states, units } from 'react-components/styles';
import { theme } from 'styled-tools';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  publications: Array<{|
    id: string,
    image: string,
    name: string,
    url: string
  |}>,
  title: string
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.ul`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: ${units(4)} ${units(6)};
  justify-content: center;
  margin-bottom: 5rem;

  ${media.min('sm')`
    margin: 0 ${units(3)} 10.5rem;
  `}
`;

/**
 * `GridItem` styled component.
 */

const GridItem = styled.li`
  flex: 1 0 ${units(24)};
  max-width: ${units(24)};
`;

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs({ rel: 'noopener', target: '_blank' })`
  color: inherit;
  display: block;
  transition: transform ${theme('transitions.fast')};

  ${states.interaction`
    transform: scale(1.1);
  `}
`;

/**
 * `Media` component.
 */

function Media(props: Props): Node {
  const { publications, title } = props;

  return (
    <Container as={'section'}>
      <Type.H3
        as={'h2'}
        marginBottom={'2.5rem'}
        marginBottomMs={'5rem'}
        textAlign={'center'}
      >
        {title}
      </Type.H3>

      <Grid>
        {publications.map(item => (
          <GridItem key={item.id}>
            <Link href={item.url}>
              <Image fluid={item.image} />
            </Link>
          </GridItem>
        ))}
      </Grid>
    </Container>
  );
}

/**
 * Export `Media` component.
 */

export default Media;
