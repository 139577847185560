// @flow

/**
 * Module dependencies.
 */

import { Container, Fill } from 'components/core/layout';
import type { FluidImage } from 'types/image';
import { Icon } from 'react-components/media';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { color, media, units } from 'react-components/styles';
import { theme } from 'styled-tools';
import Link from 'components/core/links/link';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import linkedinIcon from 'assets/svg/social/linkedin.svg';
import styled from 'styled-components';
import twitterIcon from 'assets/svg/social/twitter.svg';

/**
 * `TeamMember` type.
 */

type TeamMember = {|
  description: string,
  id: string,
  linkedin: ?string,
  name: string,
  photo: string,
  role: string,
  twitter: ?string
|};

/**
 * `Props` type.
 */

type Props = {|
  description: string,
  image: FluidImage,
  members: Array<TeamMember>,
  title: string
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
`;

/**
 * `Illustration` styled component.
 */

const Illustration = styled.div`
  left: 50%;
  max-width: 350px;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 100%;
`;

/**
 * `TextContentCell` styled component.
 */

const TextContentCell = styled.div`
  grid-column: 3 / span 6;
  margin-top: 10rem;

  ${media.min('ms')`
    margin-top: 13rem;
  `}
`;

/**
 * `TeamMembers` styled component.
 */

const TeamMembers = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${units(3)};
  justify-content: center;
  margin: 0 auto 6rem;
  max-width: ${units(118)};

  ${media.min('ms')`
    margin-bottom: 9rem;
  `}
`;

/**
 * `MemberCardOverlay` styled component.
 */

const MemberCardOverlay = styled(Fill)`
  background-color: ${color('white')};
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  height: 100%; /* Necessary for Safari */
  opacity: 0;
  padding: 1.5rem 12px 1rem;
  transform: translateY(100%);
  transition: ${theme('transitions.fast')};
  transition-property: opacity, transform;
`;

/**
 * `MemberCardWrapper` styled component.
 */

const MemberCardWrapper = styled.div`
  background-color: ${color('white')};
  border-radius: 8px;
  box-shadow: 0 0 2px 0 rgba(105, 122, 137, 0.5);
  flex: 0 0 ${units(27)};
  overflow: hidden;
  position: relative;

  &:hover,
  &:focus-within {
    & > ${MemberCardOverlay} {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

/**
 * `MemberCardImage` styled component.
 */

const MemberCardImage = styled.div`
  padding-bottom: 100%;
  position: relative;
  width: 100%;
`;

/**
 * `MemberCardFooter` styled component.
 */

const MemberCardFooter = styled.div`
  padding: 15px 12px 11px;
`;

/**
 * `MemberCardSocialNetworks` styled component.
 */

const MemberCardSocialNetworks = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${units(4)};
  justify-content: center;
  margin-top: 1rem;
`;

/**
 * `MemberCard` component.
 */

function MemberCard(props: TeamMember): Node {
  const { description, linkedin, name, photo, role, twitter } = props;

  return (
    <MemberCardWrapper>
      <MemberCardImage>
        <Fill>
          <Image fluid={photo} />
        </Fill>
      </MemberCardImage>

      <MemberCardFooter>
        <Type.Paragraph textAlign={'center'}>
          {name}
        </Type.Paragraph>

        <Type.Small
          as={'p'}
          color={color('grey700')}
          textAlign={'center'}
        >
          {role}
        </Type.Small>
      </MemberCardFooter>

      <MemberCardOverlay>
        <Type.Paragraph
          marginBottom={'10px'}
          textAlign={'center'}
        >
          {name}
        </Type.Paragraph>

        <Type.Small
          as={'div'}
          color={color('grey700')}
          textAlign={'center'}
        >
          <RawHtml>
            {description}
          </RawHtml>
        </Type.Small>

        {(twitter || linkedin) && (
          <MemberCardSocialNetworks>
            {twitter && (
              <Link
                aria-label={'Twitter'}
                colorTheme={'grey'}
                href={twitter}
                rel={'noopener'}
                target={'_blank'}
              >
                <Icon
                  aria-hidden
                  icon={twitterIcon}
                  size={'1.5rem'}
                />
              </Link>
            )}

            {linkedin && (
              <Link
                aria-label={'LinkedIn'}
                colorTheme={'grey'}
                href={linkedin}
                rel={'noopener'}
                target={'_blank'}
              >
                <Icon
                  aria-hidden
                  icon={linkedinIcon}
                  size={'1.5rem'}
                />
              </Link>
            )}
          </MemberCardSocialNetworks>
        )}
      </MemberCardOverlay>
    </MemberCardWrapper>
  );
}

/**
 * `Team` component.
 */

function Team(props: Props): Node {
  const { description, image, members, title } = props;

  return (
    <Container>
      <Illustration>
        <Image fluid={image} />
      </Illustration>

      <Grid>
        <TextContentCell>
          <Type.H3
            as={'h2'}
            marginBottom={'2.5rem'}
            textAlign={'center'}
          >
            {title}
          </Type.H3>

          <Type.H5
            as={'div'}
            marginBottom={'5rem'}
            textAlign={'center'}
          >
            <RawHtml>
              {description}
            </RawHtml>
          </Type.H5>
        </TextContentCell>
      </Grid>

      <TeamMembers>
        {members.map(item => (
          <MemberCard
            key={item.id}
            {...item}
          />
        ))}
      </TeamMembers>
    </Container>
  );
}

/**
 * Export `Team` component.
 */

export default Team;
