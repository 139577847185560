// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { Type } from 'react-components/typography';
import { media } from 'react-components/styles';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  description: string,
  title: string
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.section`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 10rem;
  padding-top: 4rem;

  ${media.min('ms')`
    grid-template-columns: repeat(10, 1fr);
    padding-bottom: 15.25rem;
  `}
`;

/**
 * `ContentCell` styled component.
 */

const ContentCell = styled.div`
  ${media.min('ms')`
    grid-column: 3 / span 6;
  `}
`;

/**
 * `Details` component.
 */

function Details(props: Props): Node {
  const { description, title } = props;

  return (
    <Container>
      <Grid>
        <ContentCell>
          <Type.H2
            marginBottom={'2.5rem'}
            textAlignMs={'center'}
          >
            {title}
          </Type.H2>

          <Type.H5
            as={'div'}
            textAlignMs={'center'}
          >
            <RawHtml>
              {description}
            </RawHtml>
          </Type.H5>
        </ContentCell>
      </Grid>
    </Container>
  );
}

/**
 * Export `Details` component.
 */

export default Details;
