// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import type { TemplateProps } from 'types/template';
import { color } from 'react-components/styles';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import Details from 'components/about-us/details';
import Footer from 'components/footer';
import Header from 'components/about-us/header';
import Media from 'components/about-us/media';
import Metatags from 'components/metatags';
import Navbar from 'components/navbar';
import PageContainer from 'components/page-container';
import React, { type Node } from 'react';
import Separator from 'components/core/separator';
import SubscriptionForm from 'components/core/subscription-form';
import Team from 'components/about-us/team';
import styled from 'styled-components';

/**
 * About us page query.
 */

const aboutUsPageQuery = graphql`
  query {
    aboutUs: seegnoCmsAboutUsPage {
      content {
        header {
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
        }
        team {
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          members {
            description
            id
            linkedin
            name
            photo {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            role
            twitter
          }
        }
        media {
          publications {
            id
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            name
            url
          }
        }
      }
    }

    bubblesLeftImage: file(
      relativePath: { eq: "images/about-us-bubbles-left.png" }
    ) {
      childImageSharp {
        fixed(width: 232) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    bubblesRightImage: file(
      relativePath: { eq: "images/about-us-bubbles-right.png" }
    ) {
      childImageSharp {
        fixed(width: 264) {
          ...GatsbyImageSharpFixed
        }
      }
    }

    separatorImage: file(
      relativePath: { eq: "images/about-us-separator.png" }
    ) {
      childImageSharp {
        fixed(width: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  color: ${color('blue800')};
  min-height: 100vh;
  overflow-x: hidden;
`;

/**
 * `HeaderWrapper` styled component.
 */

const HeaderWrapper = styled.div`
  background: linear-gradient(231.15deg, ${color('white')} 0%, ${color('grey100')} 100%);
  position: relative;
`;

/**
 * `FooterWrapper` styled component.
 */

const FooterWrapper = styled.div`
  background-color: ${color('blue800')};
  padding-top: 7.5rem;
`;

/**
 * Normalize team members.
 */

function normalizeTeamMembers(list) {
  if (!list) {
    return [];
  }

  return list.map(item => ({
    ...item,
    photo: getFluidImage(item, 'photo')
  }));
}

/**
 * Normalize publications.
 */

function normalizePublications(list) {
  if (!list) {
    return [];
  }

  return list.map(item => ({
    ...item,
    image: getFluidImage(item, 'image')
  }));
}

/**
 * `AboutUs` page.
 */

function AboutUs({ pageContext }: TemplateProps): Node {
  const {
    aboutUs,
    bubblesLeftImage,
    bubblesRightImage,
    separatorImage
  } = useStaticQuery(aboutUsPageQuery);

  const content = pageContext?.content;
  const teamMembers = normalizeTeamMembers(aboutUs?.content?.team?.members);
  const publications = normalizePublications(
    aboutUs?.content?.media?.publications
  );

  return (
    <PageContainer>
      <Wrapper>
        <Metatags {...pageContext?.metatags} />

        <HeaderWrapper>
          <Navbar colorTheme={'secondary'} />

          <Header
            {...content?.header}
            bubblesLeftImage={bubblesLeftImage?.childImageSharp?.fixed}
            bubblesRightImage={bubblesRightImage?.childImageSharp?.fixed}
            image={getFluidImage(aboutUs.content.header)}
            separatorImage={separatorImage?.childImageSharp?.fixed}
          />

          <Details {...content?.details} />
        </HeaderWrapper>

        <Team
          {...content?.team}
          image={getFluidImage(aboutUs.content.team)}
          members={teamMembers}
        />

        <Media
          {...content?.media}
          publications={publications}
        />

        <FooterWrapper>
          <Container>
            <SubscriptionForm />

            <Separator />
          </Container>

          <Footer />
        </FooterWrapper>
      </Wrapper>
    </PageContainer>
  );
}

/**
 * Export `AboutUs` page.
 */

export default AboutUs;
