// @flow

/**
 * Module dependencies.
 */

import { Box, Container } from 'components/core/layout';
import type { FixedImage, FluidImage } from 'types/image';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { media } from 'react-components/styles';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import SocialNetworks from 'components/social-networks';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  bubblesLeftImage: FixedImage,
  bubblesRightImage: FixedImage,
  description: string,
  image: FluidImage,
  lead: string,
  separatorImage: FixedImage,
  title: string
|};

/**
 * `Grid` styled component.
 */

const Grid = styled.header`
  align-items: center;
  display: grid;
  padding: 5rem 0;

  ${media.min('ms')`
    grid-template-columns: repeat(10, 1fr);
    padding: 8rem 0 5rem;
  `}

  ${media.min('md')`
    padding: 7rem 0;
  `}
`;

/**
 * `ContentCell` styled component.
 */

const ContentCell = styled.div`
  grid-row: 2;

  ${media.min('ms')`
    grid-column: 1 / span 6;
    grid-row: 1;
  `}

  ${media.min('md')`
    grid-column: 1 / span 5;
    margin-bottom: 1rem;
  `}
`;

/**
 * `IllustrationCell` styled component.
 */

const IllustrationCell = styled.div`
  grid-row: 1;
  justify-self: center;
  margin-bottom: 2.5rem;
  margin-top: -5%;
  max-width: 250px;
  width: 100%;

  ${media.min('ms')`
    grid-column: 7 / -1;
    margin: 0;
    max-width: initial;
  `}

  ${media.min('md')`
    grid-column: 6 / -1;
    margin-left: 2rem;
  `}
`;

/**
 * `BottomGrid` styled component.
 */

const BottomGrid = styled.header`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr;

  ${media.min('ms')`
    grid-template-columns: repeat(10, 1fr);
  `}
`;

/**
 * `SeparatorCell` styled component.
 */

const SeparatorCell = styled.div`
  justify-self: center;

  ${media.min('ms')`
    grid-column: 1 / -1;
  `}
`;

/**
 * `BubblesLeft` styled component.
 */

const BubblesLeft = styled.div`
  display: none;

  ${media.min('ms')`
    display: block;
    left: -4rem;
    position: absolute;
    top: 0;
  `}

  ${media.min('md')`
    top: -6rem;
  `}
`;

/**
 * `BubblesRight` styled component.
 */

const BubblesRight = styled.div`
  display: none;

  ${media.min('ms')`
    display: block;
    position: absolute;
    right: -2.2rem;
    top: 1.8rem;
  `}
`;

/**
 * `Header` component.
 */

function Header(props: Props): Node {
  const {
    bubblesLeftImage,
    bubblesRightImage,
    description,
    image,
    lead,
    separatorImage,
    title
  } = props;

  return (
    <>
      <Box position={'relative'}>
        <Container>
          <Grid>
            <ContentCell>
              <Type.H2
                as={'h1'}
                marginBottom={'2.5rem'}
              >
                {title}
              </Type.H2>

              <Type.H5
                as={'div'}
                paddingRight={'10%'}
              >
                <RawHtml>
                  {description}
                </RawHtml>
              </Type.H5>
            </ContentCell>

            <IllustrationCell>
              <Image fluid={image} />
            </IllustrationCell>
          </Grid>
        </Container>

        <SocialNetworks colorTheme={'secondary'} />
      </Box>

      <Container>
        <BubblesLeft>
          <Image fixed={bubblesLeftImage} />
        </BubblesLeft>

        <BubblesRight>
          <Image fixed={bubblesRightImage} />
        </BubblesRight>

        <BottomGrid>
          <Type.H4
            as={'div'}
            gridColumnMs={'3 / span 6'}
            letterSpacing={'-0.4px'}
            lineHeight={'36px'}
            marginBottom={'4.5rem'}
            textAlign={'center'}
          >
            <RawHtml>
              {lead}
            </RawHtml>
          </Type.H4>

          <SeparatorCell>
            <Image fixed={separatorImage} />
          </SeparatorCell>
        </BottomGrid>
      </Container>
    </>
  );
}

/**
 * Export `Header` component.
 */

export default Header;
